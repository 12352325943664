<template>
  <div class="buyOrderMsg">
    <div class="cell">
      <div v-if="listData.list&&listData.list.length!=0"></div>
      <div v-else>
        <el-empty :image="shopCart" description="尚未有订单更新"></el-empty>
      </div>
    </div>
  </div>
</template>
<script>
import { notice } from "@/api/buy.js";
export default {
  data() {
    return {
      shopCart: require("@/assets/img/app/shoppingCart.png"),
      listData: "",
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      notice().then((res) => {
        if (res.code == 1) {

          this.listData = res.data;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.buyOrderMsg {
  padding: 20px;
  .cell {
    padding: 20px;
    background: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }
}
</style>